<template>
	<div id="app">
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container-fluid">
				<a class="navbar-brand">1x2 Filtered</a>
				<div v-if="token" class="navbar-nav">
					<a class="nav-link" style="cursor: pointer;" @click="removeToken()">Log Out</a>
				</div>
			</div>
		</nav>
		<div v-if="!token" class="container">
			<div class="card" style="max-width: 480px; margin: 0 auto;">
				<div class="card-body">
    				<h5 class="card-title">Login</h5>
					<div class="mb-3">
						<label for="token-field" class="form-label">Login Token</label>
						<input v-model="login_token" type="text" class="form-control" id="token-field">
					</div>
					<button class="btn btn-secondary" @click="setToken()">Login</button>
				</div>
			</div>
		</div>
		<div v-else class="container">
			<div class="table-responsive">
				<table class="table">
					<thead>
						<tr>
							<th>Date Time</th>
							<th>Match</th>
							<th>Home</th>
							<th>Home Win</th>
							<th>Draw</th>
							<th>Away Win</th>
							<th>Away</th>
							<th>Score</th>
						</tr>
					</thead>
					<tbody v-if="error">
						<tr>
							<td colspan="8">Error! Server did not respond. Maybe wrong token?</td>
						</tr>
					</tbody>
					<tbody v-else-if="!matches">
						<tr>
							<td colspan="8">Loading...</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr v-for="match in matches" :key="match.id">
							<td>
								<strong>{{ match.state }}</strong><br>
								{{ match.datetime }}
							</td>
							<td :style="`background-color: #${match.color}; color: white;`"><strong>{{ match.match }}</strong></td>
							<td>{{ match.home }}</td>
							<td>
								{{ match.one_start }}<br>
								<span :style="`color: ${match.one_start >= match.one_now ? '#008000' : '#CC0000'}; font-weight: 600;`">{{ match.one_now }}</span>
							</td>
							<td>
								{{ match.x_start }}<br>
								<span :style="`color: ${match.x_start >= match.x_now ? '#008000' : '#CC0000'}; font-weight: 600;`">{{ match.x_now }}</span>
							</td>
							<td>
								{{ match.two_start }}<br>
								<span :style="`color: ${match.two_start >= match.two_now ? '#008000' : '#CC0000'}; font-weight: 600;`">{{ match.two_now }}</span>
							</td>
							<td>{{ match.away }}</td>
							<td>
								{{ match.score }}<br>
								<span v-if="match.score_2">({{ match.score_2 }})</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<style scoped>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
.navbar {
	padding-top: calc(0.5rem + var(--sat));
	padding-left: var(--sal);
	padding-right: var(--sar);
}
.container {
	margin-top: 60px;
	padding-left: calc(var(--bs-gutter-x, 0.75rem) + var(--sal));
	padding-right: calc(var(--bs-gutter-x, 0.75rem) + var(--sar));
}
</style>

<style>
:root {
    --sat: constant(safe-area-inset-top);
    --sat: env(safe-area-inset-top);
	/* --sat: 64px; */
    --sar: constant(safe-area-inset-right);
    --sar: env(safe-area-inset-right);
    --sab: constant(safe-area-inset-bottom);
    --sab: env(safe-area-inset-bottom);
	/* --sab: 64px; */
    --sal: constant(safe-area-inset-left);
    --sal: env(safe-area-inset-left);
}
</style>

<script>
export default {
	name: "App",
	data: () => ({
		matches: null,
		login_token: null,
		token: null,
		error: false
	}),
	mounted () {
		this.init()
	},
	methods: {
		init () {
			this.token = localStorage.getItem('token')
			if (!this.token) return
			this.getMatches()
			setInterval(() => {
				this.getMatches()
			}, 65000)
		},
		getMatches() {
			this.axios.get(`api.php?token=${localStorage.getItem('token')}`)
				.then(response => {
					this.error = false
					this.matches = response.data
				})
				.catch(err => {
					this.error = true
					console.log(err)
				})
		},
		setToken () {
			localStorage.setItem('token', this.login_token)
			this.login_token = null
			this.init()
		},
		removeToken () {
			localStorage.removeItem('token')
			this.init()
		}
	}
}
</script>