import Vue from 'vue'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.prototype.$isDev = process.env.NODE_ENV !== 'production'

Vue.prototype.$api_url = Vue.prototype.$isDev ? "http://localhost:3000/" : "/"

Vue.config.productionTip = false

Vue.use(VueAxios, axios.create({
	baseURL: Vue.prototype.$api_url
}))

new Vue({
	render: h => h(App),
}).$mount('#app')
